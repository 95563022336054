import React, { useState } from "react";

// Router
//React Router
import { Link } from "react-router-dom";

//React Intl
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navigationMenu">
      <input
        type="checkbox"
        className="navigationMenu__checkbox"
        id="navi-toggle"
        value={isOpen}
        onChange={handleOpen}
        checked={isOpen}
      />

      <label className="navigationMenu__button" htmlFor="navi-toggle">
        <FontAwesomeIcon
          icon={faBars}
          style={{ color: "#deb992", width: "100%", fontSize: "2rem" }}
        />
      </label>

      <div className="navigationMenu__background"></div>

      <nav className="navigationMenu__nav">
        <ul className="navigationMenu__list">
          <li className="navigationMenu__item">
            <Link to="/" className="navigationMenu__link" onClick={handleOpen}>
              <FormattedMessage id="navbar.home" defaultMessage="Home" />
            </Link>
          </li>

          <li className="navigationMenu__item">
            <Link
              to="/wineries"
              className="navigationMenu__link"
              onClick={handleOpen}
            >
              <FormattedMessage
                id="navbar.wineries"
                defaultMessage="Wineries"
              />
            </Link>
          </li>
          <li className="navigationMenu__item">
            <Link
              to="#"
              className="navigationMenu__link navigationMenu__link--disabled"
              onClick={(e) => {
                e.preventDefault();
                handleOpen();
              }}
              style={{ opacity: 0.5, cursor: "not-allowed" }}
            >
              <FormattedMessage id="navbar.wines" defaultMessage="Wines" />
              <span className="navigationMenu__comingSoon">
                <FormattedMessage
                  id="navbar.comingSoon"
                  defaultMessage="Coming soon"
                />
              </span>
            </Link>
          </li>
          <li className="navigationMenu__item">
            <Link
              to="#"
              className="navigationMenu__link navigationMenu__link--disabled"
              onClick={(e) => {
                e.preventDefault();
                handleOpen();
              }}
              style={{ opacity: 0.5, cursor: "not-allowed" }}
            >
              <FormattedMessage
                id="navbar.articles"
                defaultMessage="Articles"
              />
              <span className="navigationMenu__comingSoon">
                <FormattedMessage
                  id="navbar.comingSoon"
                  defaultMessage="Coming soon"
                />
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
