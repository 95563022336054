import {
  GET_ARTICLES,
  GET_ARTICLE,
  SET_FILTERED_ARTICLES,
  CLEAR_FILTERED_ARTICLES,
  SET_LOADING,
} from "../actions/types";

const initialState = {
  articles: [],
  article: {},
  typeFilter: null,
  loading: false,
  error: null,
};

const articleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE:
      return {
        ...state,
        article: action.payload,
        loading: false,
      };
    case GET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
        loading: false,
      };
    case SET_FILTERED_ARTICLES:
      return {
        ...state,
        typeFilter: action.typeFilter,
        loading: false,
      };
    case CLEAR_FILTERED_ARTICLES:
      return {
        ...state,
        typeFilter: null,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default articleReducer;
