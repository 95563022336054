import {
  GET_WINES,
  SEARCH_WINES,
  CLEAR_FILTERED_WINES,
  GET_WINE,
  SET_LOADING,
  SET_FILTERED_WINES,
  CLEAR_SEARCHED_WINES,
} from "../actions/types";

const initialState = {
  wines: [],
  winesFromSearch: [],
  searchFilter: null,
  typeFilter: null,
  wine: null,
  loading: false,
  error: null,
};

const wineReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WINES:
      return {
        ...state,
        wines: action.payload,
        loading: false,
      };
    case GET_WINE:
      return {
        ...state,
        wine: action.payload,
        loading: false,
      };
    case SEARCH_WINES:
      return {
        ...state,
        winesFromSearch: action.payload,
        searchFilter: action.searchFilter,
        loading: false,
      };
    case CLEAR_SEARCHED_WINES:
      return {
        ...state,
        winesFromSearch: null,
        searchFilter: null,
        loading: false,
      };
    case SET_FILTERED_WINES:
      return {
        ...state,
        typeFilter: action.typeFilter,
        loading: false,
      };
    case CLEAR_FILTERED_WINES:
      return {
        ...state,
        typeFilter: null,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default wineReducer;
