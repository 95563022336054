import React from "react";

//React Router
import { Link } from "react-router-dom";

//React Intl
import { FormattedMessage } from "react-intl";

//React Bootstrap
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Components
import Menu from "./Menu";
import LangToggler from "../LangToggler/LangToggler";

const Navigation = () => {
  return (
    <div className={`navigation bg-solid`}>
      <Menu />
      <div className="navigation__bottom">
        <div className="navigation__menu">
          <div className="navigation__langToggler">
            <LangToggler />
          </div>
          <div className="navigation__items">
            <ul className="navigation__ul">
              <li>
                <Link to="/">
                  <FormattedMessage id="navbar.home" defaultMessage="Home" />
                </Link>
              </li>
              <li>
                <Link to="/wineries">
                  <FormattedMessage
                    id="navbar.wineries"
                    defaultMessage="Wineries"
                  />
                </Link>
              </li>
              <li>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <FormattedMessage
                        id="navbar.comingSoon"
                        defaultMessage="Coming soon"
                      />
                    </Tooltip>
                  }
                >
                  <div>
                    <Link
                      to="#"
                      onClick={(e) => e.preventDefault()}
                      style={{ opacity: 0.5, cursor: "not-allowed" }}
                    >
                      <FormattedMessage
                        id="navbar.wines"
                        defaultMessage="Wines"
                      />
                    </Link>
                  </div>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      <FormattedMessage
                        id="navbar.comingSoon"
                        defaultMessage="Coming soon"
                      />
                    </Tooltip>
                  }
                >
                  <div>
                    <Link
                      to="#"
                      onClick={(e) => e.preventDefault()}
                      style={{ opacity: 0.5, cursor: "not-allowed" }}
                    >
                      <FormattedMessage
                        id="navbar.articles"
                        defaultMessage="Articles"
                      />
                    </Link>
                  </div>
                </OverlayTrigger>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
