import React from "react";
import LegalLayout from "../../components/Legal/LegalLayout";
import { FormattedMessage } from "react-intl";

const PrivacyPolicy = () => {
  return (
    <LegalLayout title="legal.privacyPolicy" lastUpdated="January 1, 2024">
      <ol>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.information.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.information.content" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="legal.privacy.information.accounts" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.information.usage" />
            </li>
          </ul>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.usage.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.usage.content" />
          </p>
          <ul>
            <li>
              <FormattedMessage id="legal.privacy.usage.services" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.usage.payments" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.usage.communications" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.usage.legal" />
            </li>
          </ul>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.sharing.title" />
            </strong>
          </p>
          <ul>
            <li>
              <FormattedMessage id="legal.privacy.sharing.selling" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.sharing.providers" />
            </li>
            <li>
              <FormattedMessage id="legal.privacy.sharing.legal" />
            </li>
          </ul>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.security.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.security.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.rights.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.rights.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.changes.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.changes.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.privacy.contact.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.privacy.contact.content" />
          </p>
        </li>
      </ol>
    </LegalLayout>
  );
};

export default PrivacyPolicy;
