import React from "react";
import LegalLayout from "../../components/Legal/LegalLayout";
import { FormattedMessage } from "react-intl";

const CookiePolicy = () => {
  return (
    <LegalLayout title="legal.cookiePolicy" lastUpdated="January 1, 2024">
      <ol>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.what.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.what.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.our.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.our.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.gdpr.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.gdpr.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.analytics.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.analytics.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.updates.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.updates.content" />
          </p>
        </li>

        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.cookie.contact.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.cookie.contact.content" />
          </p>
        </li>
      </ol>
    </LegalLayout>
  );
};

export default CookiePolicy;
