import React from "react";
import { FormattedMessage } from "react-intl";
import Container from "react-bootstrap/Container";

const LegalLayout = ({ title, lastUpdated, children }) => {
  return (
    <Container className="legal-container py-5">
      <div className="legal-content">
        <h1 className="mb-4">
          <FormattedMessage id={title} />
        </h1>
        <p className="text-muted mb-4">
          <FormattedMessage
            id="legal.lastUpdated"
            values={{ date: lastUpdated }}
          />
        </p>
        {children}
      </div>
    </Container>
  );
};

export default LegalLayout;
