import React, { useContext } from "react";
import { Context } from "../IntlWrapper/IntlWrapper";

function LangToggler() {
  const { locale, selectLang, languages } = useContext(Context);

  return (
    <div className="langToggler">
      <select
        value={locale}
        onChange={selectLang}
        className="langToggler__select"
        aria-label="Select language"
      >
        {Object.values(languages).map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.code.split("-")[0].toUpperCase()}
          </option>
        ))}
      </select>
    </div>
  );
}

export default LangToggler;
