import React, { useState, useEffect } from "react";

//React Intl
import { IntlProvider } from "react-intl";
import English from "../../languages/en-US.json";
import Spanish from "../../languages/es-MX.json";

export const Context = React.createContext();

const LANGUAGES = {
  en: {
    code: "en",
    name: "English",
    messages: English,
  },
  es: {
    code: "es",
    name: "Español",
    messages: Spanish,
  },
};

// Get language from localStorage or fallback to browser preference
const getInitialLanguage = () => {
  // 1. Check localStorage for explicit user preference
  const savedLanguage = localStorage.getItem("userLanguage");
  if (savedLanguage && LANGUAGES[savedLanguage]) {
    return savedLanguage;
  }

  // 2. Check browser's preferred languages list
  if (navigator.languages) {
    for (const lang of navigator.languages) {
      // Always get base language code
      const baseLanguage = lang.split("-")[0];
      if (LANGUAGES[baseLanguage]) {
        return baseLanguage;
      }
    }
  }

  // 3. Check browser's primary language
  const browserLang = navigator.language.split("-")[0];
  if (LANGUAGES[browserLang]) {
    return browserLang;
  }

  // 4. Fallback to English
  return "en";
};

const IntlWrapper = ({ children }) => {
  const [locale, setLocale] = useState(getInitialLanguage());

  useEffect(() => {
    // Persist language preference
    localStorage.setItem("userLanguage", locale);
    // Update document language for accessibility
    document.documentElement.lang = locale;
  }, [locale]);

  const selectLang = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);
  };

  // Create a richer context value
  const contextValue = {
    locale,
    selectLang,
    languages: LANGUAGES,
    currentLanguage: LANGUAGES[locale],
  };

  return (
    <Context.Provider value={contextValue}>
      <IntlProvider
        messages={LANGUAGES[locale].messages}
        locale={locale}
        browserLocale={navigator.language.split("-")[0]}
        defaultLocale="en"
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default IntlWrapper;
