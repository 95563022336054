import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import IntlWrapper from "./components/IntlWrapper/IntlWrapper";
import Navigation from "./components/NavBar/Navbar";
import Loading from "./components/Loading/Loading";

//Styles
import "./sass/main.css";

//Redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";

//Routes
import AppRoutes from "./Routes/Routes";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlWrapper>
          <BrowserRouter>
            <Suspense fallback={<Loading />}>
              <div className="app">
                <Navigation />
                <main className="main">
                  <AppRoutes />
                </main>
              </div>
            </Suspense>
          </BrowserRouter>
        </IntlWrapper>
      </PersistGate>
    </Provider>
  );
};

export default App;
