import { combineReducers } from "redux";
import wineryReducer from "./wineryReducer";
import wineReducer from "./wineReducer";
import articleReducer from "./articleReducer";
import userReducer from "./userReducer";
import producersFormReducer from "./producersFormReducer";
import enumReducer from "./enumReducer";
export default combineReducers({
  article: articleReducer,
  wine: wineReducer,
  winery: wineryReducer,
  user: userReducer,
  producersForm: producersFormReducer,
  enums: enumReducer,
});
