import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const Loading = ({ fullscreen = false, contained = false }) => {
  const loadingClass = fullscreen
    ? "loading loading--fullscreen"
    : contained
    ? "loading loading--contained"
    : "loading";

  return (
    <div
      className={loadingClass}
      aria-label={
        <FormattedMessage id="loading.label" defaultMessage="Loading content" />
      }
    >
      <div className="loading__spinner" />
    </div>
  );
};

Loading.propTypes = {
  fullscreen: PropTypes.bool,
  contained: PropTypes.bool,
};

export default Loading;
