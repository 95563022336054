import React from "react";
import { Link } from "react-router-dom";

const FourOfour = () => {
  return (
    <div className="fourOfour">
      <h2>The Page you are looking for does not exist</h2>
      <Link to="/">
        <button className="btn btn--primary">Go back home</button>
      </Link>
    </div>
  );
};

export default FourOfour;
