import { GET_ENUMS, SET_LOADING } from "../actions/types";

const initialState = {
  enums: [],
  enEnums: [],
  esEnums: [],
  loading: false,
  error: null,
};

const enumReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ENUMS:
      return {
        ...state,
        enums: action.payload,
        enEnums: action.payload.filter((item) => item.locale === "en"),
        esEnums: action.payload.filter((item) => item.locale === "es"),
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default enumReducer;
