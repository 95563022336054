import React from "react";
import LegalLayout from "../../components/Legal/LegalLayout";
import { FormattedMessage } from "react-intl";

const TermsOfService = () => {
  return (
    <LegalLayout title="legal.termsOfService" lastUpdated="January 1, 2024">
      <ol>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.introduction.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.introduction.content" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.services.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.services.content" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.responsibilities.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.responsibilities.age" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.responsibilities.misuse" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.responsibilities.content" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.payments.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.payments.fees" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.payments.changes" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.ip.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.ip.ownership" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.ip.usage" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.disclaimers.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.disclaimers.warranty" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.disclaimers.accuracy" />
          </p>
          <p>
            <FormattedMessage id="legal.terms.disclaimers.liability" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.changes.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.changes.content" />
          </p>
        </li>
        <li>
          <p>
            <strong>
              <FormattedMessage id="legal.terms.law.title" />
            </strong>
          </p>
          <p>
            <FormattedMessage id="legal.terms.law.content" />
          </p>
        </li>
      </ol>
    </LegalLayout>
  );
};

export default TermsOfService;
