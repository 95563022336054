import {
  GET_WINERIES,
  SEARCH_WINERIES,
  GET_WINERY,
  SET_LOADING,
  CLEAR_SEARCHED_WINERIES,
  CLEAR_WINERY,
  GET_HOME_WINERIES,
  APPEND_WINERIES,
  APPEND_SEARCH_WINERIES,
  GET_WINERIES_ERROR,
  SEARCH_WINERIES_ERROR,
} from "../actions/types";

const initialState = {
  wineries: {
    wineries: [],
    pagination: {
      total: 0,
      page: 1,
      pages: 1,
    },
  },
  homeWineries: [],
  regionFilter: null,
  searchFilter: null,
  searchResults: null,
  winery: null,
  loading: false,
  error: null,
};

const wineryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WINERIES:
      return {
        ...state,
        wineries: action.payload,
        loading: false,
      };
    case APPEND_WINERIES:
      return {
        ...state,
        wineries: {
          ...action.payload,
          wineries: [...state.wineries.wineries, ...action.payload.wineries],
          pagination: action.payload.pagination,
        },
        loading: false,
      };
    case GET_HOME_WINERIES:
      return {
        ...state,
        homeWineries: action.payload,
        loading: false,
      };
    case GET_WINERIES_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };
    case GET_WINERY:
      return {
        ...state,
        winery: action.payload,
        loading: false,
      };
    case SEARCH_WINERIES:
      return {
        ...state,
        searchResults: {
          wineries: action.payload.wineries || [],
          pagination: action.payload.pagination,
        },
        searchFilter: action.searchFilter,
        regionFilter: action.regionFilter,
        loading: false,
      };
    case APPEND_SEARCH_WINERIES:
      return {
        ...state,
        searchResults: {
          wineries: [
            ...state.searchResults.wineries,
            ...action.payload.wineries,
          ],
          pagination: action.payload.pagination,
        },
        searchFilter: action.searchFilter,
        regionFilter: action.regionFilter,
        loading: false,
      };
    case SEARCH_WINERIES_ERROR:
      return {
        ...state,
        error: true,
        searchResults: null,
        searchFilter: null,
        regionFilter: null,
        loading: false,
      };
    case CLEAR_SEARCHED_WINERIES:
      return {
        ...state,
        searchResults: null,
        searchFilter: null,
        regionFilter: null,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_WINERY:
      return {
        ...state,
        winery: null,
        loading: false,
      };
    default:
      return state;
  }
};

export default wineryReducer;
